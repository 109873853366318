export {
    disp_video_alert
}
import {
    disp_alert,
} from "../common/ui";

function disp_video_alert(body, code) {
    var style = 'position: absolute; top: 2px; right: 2px;'
    var prepend_element = "#video";
    if (code) {
        if (code == "AbortError") {
            body = body + "　" + "カメラとマイクの利用ができませんでした。"
        } else if (code == "NotAllowedError") {
            body = body + "　" + "カメラとマイクへのアクセスを許可してください。"
        } else if (code == "NotFoundError") {
            body = body + "　" + "使用できるカメラとマイクが見つかりません。カメラとマイクを接続していない場合は、接続してください。"
        } else if (code == "NotReadableError") {
            body = body + "　" + "カメラとマイクの利用ができませんでした。カメラやマイクが他のブラウザで使用中の場合は終了し、再度この画面を開いてください。"
        } else if (code == "OverconstrainedError") {
            body = body + "　" + "使用できるカメラとマイクが見つかりません。カメラとマイクへのアクセスを許可していない場合は許可してください。"
        } else if (code == "SecurityError") {
            body = body + "　" + "カメラとマイクへのアクセスを拒否されました。ブラウザを変更して、再度この画面を開いてください。"
        } else if (code == "TypeError") {
            body = body + "　" + "通話を開始できませんでした。"
        }
    }
    disp_alert(body, prepend_element, style);
}