import {
    disp_spinner_button,
    disp_flashing,
    disp_add_form,
    is_display,
} from "../common/ui"
import {
    disp_open_file,
    disp_open_file_image,
    destroy_file,
} from "../common/file_upload"

$(function () {
    if(!is_display(".channel.new") &&
    !is_display(".channel.create") &&
    !is_display(".channel.edit") &&
    !is_display(".channel.update")){
        return;
    }

    $('#image').change(function () {
        //サイズ計算
        var total_size = 0;
        var files = $(this).prop('files')

        if (files.length > 0) {
            $.each(files, function () {
                total_size = total_size + this.size
            });
        }

        //20MBを越えた場合はアラート表示
        if (20000000 < total_size) {
            alert('選択されたファイルサイズが20MBを超えています。ファイルを圧縮するか減らしてください。');
            $(this).val("").change();
        }
    });

    disp_flashing("#image", "#register", "#register");
    disp_open_file_image("#image", ".image");
    destroy_file("#image", ".image", ".image_destroy", "#image_destroy");

    
    disp_add_form(".date .plus",".form_temp",".form_area", 20, "dates", ".date_destroy");


    $(".select2").select2();

});