import {
  firebaseError,
  GoogleLoginAndAppLogin,
  MailLoginAndAppLogin,
} from "./firebase"
$(function () {

  if ($(".session.new").length <= 0 &&
    $(".registration.edit_firebase_user").length <= 0) {
    return;
  }

  $(".google").on('click', function () {
    GoogleLoginAndAppLogin()
      .catch(function (error) {
        firebaseError(error)
      })
  });

  $(".mail").on('click', function () {
    MailLoginAndAppLogin()
      .catch(function (error) {
        firebaseError(error)
      })
  });
});