export {
  getFirebaseConfig
}

//dev
const devConfig = {
  apiKey: "AIzaSyAALgv9e-4LUX7fr64w4JY_TNVRebL8-Zg",
  authDomain: "pet-caring-system.firebaseapp.com",
};

//pro
const proConfig = {
  apiKey: "AIzaSyAALgv9e-4LUX7fr64w4JY_TNVRebL8-Zg",
  authDomain: "pet-caring-system.firebaseapp.com",
};

const getFirebaseConfig = () => {

  if (gon.env == "production") {
    return proConfig;
  } else {
    return devConfig;
  }
}