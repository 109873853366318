export {
  Scaling
}
const MAX_SCALE = 5;
const SCALE_STEP = 0.2

class Scaling {

  constructor(canvas_selector) {


    var capture_canvas = $(canvas_selector);
    var scale_canvas = $(canvas_selector).clone().attr("id", "scale");
    capture_canvas.before(scale_canvas);

    this.capture_canvas = capture_canvas.hide();
    this.scale_canvas = scale_canvas;

    this.ctx = this.scale_canvas[0].getContext('2d')
    this.ctx.drawImage(this.capture_canvas[0], 0, 0);

    this.image_scale = 1;
    this.mouse_move_x = 0;
    this.mouse_move_y = 0;
    this.press = false;
    this.zoom_left_buf = 0;
    this.zoom_top_buf = 0;
    this.sx = 0;
    this.sy = 0;
    this.sw = this.scale_canvas[0].width;
    this.sh = this.scale_canvas[0].height;

    this.grid_mode = false;
    
    this.event_set();
  }

  event_set() {

    $(this.scale_canvas).on('mousedown', function () {
      this.zoom_left_buf = this.sx;
      this.zoom_top_buf = this.sy;
      this.press = true;
    }.bind(this));

    $(this.scale_canvas).on('mousemove', function (e) {
      this.mouse_move(e);
    }.bind(this));

    $(this.scale_canvas).on('mouseup', function (e) {
      this.press = false;
    }.bind(this));

    $(this.scale_canvas).on('mouseout', function (e) {
      this.press = false;
    }.bind(this));
  }


  draw() {
    var dw = this.scale_canvas[0].width;
    var dh = this.scale_canvas[0].height;

    this.ctx.drawImage(this.capture_canvas[0], this.sx, this.sy, this.sw, this.sh, 0, 0, dw, dh);

    if (this.grid_mode) {
      this.grid();
    }
  }

  zoom_up() {
    if (this.image_scale >= MAX_SCALE) {
      return;
    }
    this.image_scale += SCALE_STEP;
    if (this.image_scale > MAX_SCALE) {
      this.image_scale = MAX_SCALE;
    }

    // 切り抜き領域の横の長さ(sw)の計算
    this.sw = this.scale_canvas[0].width / this.image_scale;
    // 切り抜き領域の縦の長さ(sh)の計算
    this.sh = this.scale_canvas[0].height / this.image_scale;

    // 切り抜き領域の開始点X座標 (sx) の計算
    this.sx += (this.scale_canvas[0].width / 2) * SCALE_STEP / (this.image_scale * (this.image_scale - SCALE_STEP));
    this.sx = Math.max(0, Math.min(this.scale_canvas[0].width - this.sw, this.sx));

    // 切り抜き領域の開始点Y座標 (sy) の計算
    this.sy += (this.scale_canvas[0].height / 2) * SCALE_STEP / (this.image_scale * (this.image_scale - SCALE_STEP));
    this.sy = Math.max(0, Math.min(this.scale_canvas[0].height - this.sh, this.sy));

    this.draw();
  }

  zoom_out() {
    if (this.image_scale <= 1) {
      return;
    }
    this.image_scale -= SCALE_STEP;
    if (this.image_scale <= 1) {
      this.image_scale = 1;
    }

    // 切り抜き領域の横の長さ(sw)の計算
    this.sw = this.scale_canvas[0].width / this.image_scale;
    // 切り抜き領域の縦の長さ(sh)の計算
    this.sh = this.scale_canvas[0].height / this.image_scale;

    // 切り抜き領域の開始点X座標 (sx) の計算
    this.sx -= (this.scale_canvas[0].width / 2) * SCALE_STEP / (this.image_scale * (this.image_scale - SCALE_STEP));
    this.sx = Math.max(0, Math.min(this.scale_canvas[0].width - this.sw, this.sx));

    // 切り抜き領域の開始点Y座標 (sy) の計算
    this.sy -= (this.scale_canvas[0].height / 2) * SCALE_STEP / (this.image_scale * (this.image_scale - SCALE_STEP));
    this.sy = Math.max(0, Math.min(this.scale_canvas[0].height - this.sh, this.sy));

    this.draw();
  }

  mouse_move(e) {
    var rect = e.target.getBoundingClientRect();

    if (this.press) {
      var mouse_drag_x = e.clientX - rect.left;
      var mouse_drag_y = e.clientY - rect.top;

      // 切り抜き領域の横の長さ(sw)の計算
      this.sw = this.scale_canvas[0].width / this.image_scale;
      // 切り抜き領域の縦の長さ(sh)の計算
      this.sh = this.scale_canvas[0].height / this.image_scale;

      this.sx = this.zoom_left_buf + (this.mouse_move_x - mouse_drag_x) / this.image_scale;
      this.sx = Math.max(0, Math.min(this.scale_canvas[0].width - this.sw, this.sx));

      this.sy = this.zoom_top_buf + (this.mouse_move_y - mouse_drag_y) / this.image_scale;
      this.sy = Math.max(0, Math.min(this.scale_canvas[0].height - this.sh, this.sy));

      this.draw();

    } else {
      this.mouse_move_x = e.clientX - rect.left;
      this.mouse_move_y = e.clientY - rect.top;
    }

  }

  destory() {
    $(this.scale_canvas).remove();
    $(this.scale_canvas).off();
    $(this.capture_canvas).show();
  }

  grid() {
    // Canvas width
    var c_width = this.scale_canvas[0].width;
    // Canvas height
    var c_height = this.scale_canvas[0].height;

    var ctx = this.ctx;
    ctx.beginPath();
    for (var x = 0; x <= c_width; x += 30) {
      ctx.moveTo(0.5 + x, 0);
      ctx.lineTo(0.5 + x, c_height);
    }

    for (var x = 0; x <= c_height; x += 30) {
      ctx.moveTo(0, 0.5 + x);
      ctx.lineTo(c_width, 0.5 + x);
    }
    ctx.strokeStyle = "white";
    ctx.closePath();
    ctx.stroke();
  }

  switch_grid() {
    if(this.grid_mode){
      this.grid_mode = false;
    } else {
      this.grid_mode = true;
    }
    
    this.draw();
  }
}