export {
  SelectElement
}

class SelectElement {

  constructor() {
    this.elements = [];
  }

  add(element) {
    
    this.elements.push(element)
    if(this.elements.length == 1){
      this.select(element)
    }
    
    var this_instance = this;
    $(element).on('click', function () {
      this_instance.select(this);
    })
  }

  destroy(element) {
    $(element).remove_class('active');
    var idx = this.elements.indexOf(element);
    if(idx != -1){
      elements[idx].splice(idx, 1);
    }
  }

  select(select_element) {
    if (this.elements.length > 0) {
      $.each(this.elements, function (i, element) {
        $(element).removeClass('active');
        $(element).css({ 'border' : 'none' } );
      });
    }

    $(select_element).addClass('active');
    $(select_element).css({ 'border' : '4px solid' , 'border-color' : '#fff' } );
  }

}