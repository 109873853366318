import Rails from '@rails/ujs';
import {
  submit_reply_message
} from "./message_submit"
import {
  disp_message,
  disp_spinner_button
} from "../common/ui"
import {
  disp_open_file,
} from "../common/file_upload"
import {
  setting_edit_event
} from "./message_edit"
import {
  get_parent_message_dom_from_messages,
  get_parent_message_dom_from_message_id,
  scroll_to_reply,
} from "./message_common.js"

export {
  setting_reply_event,
  received_reply,
  received_replys,
  create_reply_input,
  setting_reply_links
}

function setting_reply_event(){
  $(".reply_button").off('click');
  $(".reply_button").click(function () {

    var message_dom = $(this).closest("div.message");
    var reply_area = message_dom.find(".reply_area");

    // リプライ表示・閉じるリンクの制御
    setting_reply_links(message_dom);

    if (reply_area.hasClass("show")) {
      return;
    }

    var reply_form = message_dom.find(".reply_form");
    // disp_spinner_button(reply_form.find(".submit_reply_button"), true)
    Rails.fire($(reply_form)[0], "submit");

    // リプライ入力欄の生成・表示
    create_reply_input(message_dom);

  })
}

// リプライ取得後のレスポンス展開処理（メッセージ１つ）
function received_reply(e, data) {

  if (data.message) {
    var message = [data.message]

    // レスポンスから親メッセージDOMの特定
    var parent_message_dom = get_parent_message_dom_from_messages(message)
    if(parent_message_dom == null){
      return;
    }
    var reply_area = parent_message_dom.find(".reply_area")


    // リプライ表示箇所が開いている場合は、最新のメッセージを表示する
    if (reply_area.hasClass("show")) {

      // リプライの表示
      disp_reply(parent_message_dom, message)

      // リプライ表示・閉じるリンクの制御
      // setting_reply_links(parent_message_dom)

      // リプライ入力欄の生成・表示
      create_reply_input(parent_message_dom);

      // 表示したリプライを編集できるようにする
      setting_edit_event();
    }

    // リプライ表示箇所が閉じている場合は、「返信を開く」を活性にする
    parent_message_dom.find(".reply_link").show();
  
  }

}

// リプライ取得後のレスポンス展開処理（メッセージ複数）
function received_replys(e, messages) {
  if (messages.length < 1) {
    return;
  }

  // レスポンスから親メッセージDOMの特定
  var parent_message_id = messages[0].parent_message_id
  var parent_message_dom = get_parent_message_dom_from_message_id(parent_message_id)
  if(parent_message_dom == null){
    return;
  }
  
  var reply_message_area = parent_message_dom.find(".reply_message_area");
  reply_message_area.empty();

  // リプライの表示
  disp_reply(parent_message_dom, messages)

  // 表示したリプライを編集できるようにする
  setting_edit_event();
}

// リプライの表示
function disp_reply(message_dom, messages) {
  // リプライ表示欄を一旦綺麗にする
  var reply_message_area = message_dom.find(".reply_message_area");

  // リプライを表示
  messages.forEach(message => disp_message(message, reply_message_area));

  var child_reply_button = reply_message_area.find(".reply_button");
  var child_reply_area = reply_message_area.find(".reply_area");
  child_reply_button.remove();
  child_reply_area.remove();
}

// リプライ表示・閉じるリンクの制御
function setting_reply_links(message_dom) {

  var reply_area = message_dom.find(".reply_area");
  reply_area.collapse('toggle');

}

// リプライ入力欄の生成・表示
function create_reply_input(message_dom) {

  var message_id = message_dom.find(".message_content").data("message_id");
  var reply_input_area = message_dom.find(".reply_input_area");

  // すでにメッセージ入力欄が存在する場合は終了
  if (reply_input_area.find(".message_reply").length > 0) {
    return;
  }

  var reply_message_area_temp = $(".message_reply.temp").clone();

  // タグ選択エリア設定
  var tag_select_area = reply_message_area_temp.find('.js-reply-select')
  tag_select_area.removeAttr("id");
  tag_select_area.select2({
    width: '100%',
    placeholder: "タグ選択"
  })
  tag_select_area.next(".select2").find("input").attr("readonly", "true");

  // 画像選択エリア設定
  var reply_image_label = reply_message_area_temp.find(".reply_image_label");
  var reply_image = reply_message_area_temp.find(".reply_image");
  var disp_image_area = reply_message_area_temp.find(".disp_image_area");
  reply_image_label.attr("for", message_id + "reply_image"); // 画像選択ラベル
  reply_image.attr("id", message_id + "reply_image"); // 画像選択本体
  disp_open_file(reply_image, disp_image_area) // 選択中画像表示

  reply_input_area.append(reply_message_area_temp);
  reply_message_area_temp.show().removeClass("temp");

  $(".submit_reply_button").click(function () { // dom作成後にイベントを設定しないと発火しないため
    // disp_spinner_button($(this), true);
    var parent_message_dom = $(this).closest("div.message");
    var current_message_content_dom = $(this).closest("div.message_content");

    var body_dom = current_message_content_dom.find("textarea.body");
    var image_dom = current_message_content_dom.find('input[type="file"]');
    var tag_dom = current_message_content_dom.find("select");
    var parent_message_id = parent_message_dom.children(".message_content").data("message_id")

    submit_reply_message(body_dom, image_dom, tag_dom, parent_message_id);
  })
}