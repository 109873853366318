import {
  checkEmail,
  firebaseError,
  getParameterByName,
} from "./firebase"
$(async function () {

  if ($(".email.check_email").length <= 0) {
    return;
  }
  var mode = getParameterByName('mode');

  var checkEmailResult = await checkEmail().catch(function (error) {
    firebaseError(error);
  });

  if (checkEmailResult && mode == "verifyEmail") {
    window.location = "/"
  } else if(!checkEmailResult && mode == "verifyEmail"){
    $(".form-signin").show();
    $(".auth_link").show();
  }
});