export{
  ScreenShot
}

class ScreenShot {

  constructor(video_selector, modal_selector, canvas_selector) {
    this.video = video_selector;
    this.modal = modal_selector;
    this.canvas = $(canvas_selector);
    
  }

  capture_to_modal(){
    if(!this.modal){
      console.log("キャプチャ取得処理に失敗しました。モーダルが指定されていません")
    }
    var modal = $(this.modal)
    if (this.capture()){
      modal.modal(); //show
    }

  }

  capture() {
    var video = $(this.video);
    if(video.length < 1){return false;}

    this.canvas.attr('width', video[0].videoWidth);
    this.canvas.attr('height', video[0].videoHeight);
    this.canvas[0].getContext('2d').drawImage(video[0], 0, 0, video[0].videoWidth, video[0].videoHeight);
    return true;
  }

}