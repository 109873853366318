import {
  disp_video_alert
} from "./ui";
import {
  is_display
} from "../common/ui";
export {
  call,
  end_call,
  connect_persons
}
let localStream = null;
let peer = null;
let room = null;
let connect_persons = {};

$(function () {

  if (!is_display(".video_chat")) {
    return;
  }
  var url = $('#examination_status_check').val();

  if ((!gon.token && !gon.channel_id) || !url) {
    return;
  }
  $.ajax({
      url: url,
      type: "POST",
      data: {
        token: gon.token,
        channel_id: gon.channel_id
      },
      dataType: "json"
    })
    .done(function (data) {
      if (data['mode'] == "examination_check") {
        if (data['result']) {
          init();
        } else {
          location.href = "/examination_room/invalid";
        }
      }
    })
    .fail(function (data) {
      alert('エラーが発生しました。')
      $("#log").append($("<p>エラーが発生しました。"+ data.toString() + new Date().toLocaleString() + "</p>"));
    })
});

async function init() {

  // 古いブラウザに対応
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function(constraints) {
  
      var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
  
      if (!getUserMedia) {
        return Promise.reject(new Error('非対応ブラウザです。'));
      }
  
      return new Promise(function(resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    }
  }

  navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    })
    .then(function (stream) {
      // Success
      $('#my-video').get(0).srcObject = stream;
      localStream = stream;
      $('#my-video')[0].play();
      $("#start_examination_modal").modal({
        backdrop: 'static'
      });
    }).catch(function (error) {
      // Error
      var message = 'エラーが発生しました。'
      disp_video_alert(message, error.name);
      send_log(error.name, error.toString())
      var constraints = navigator.mediaDevices.getSupportedConstraints();
      if (constraints) {
        send_log("", constraints)
      }
      $("#log").append($("<p>エラーが発生しました。"+ error.toString() + new Date().toLocaleString() + "</p>"));
      return;
    });

  peer = new Peer({
    key: gon.skyway_api_key,
    debug: 3
  });
  peer.on('error', function (err) {
    disp_video_alert(err.message);
    send_log("", err.message)
  });
  peer.on('open', function () {
    $('#my-id').text(peer.id);
  });
}

function call() {
  if (peer == null || localStream == null) {
    return;
  }
  if (!gon.token) {
    disp_video_alert("ルームIDが取得できませんでした。")
  }

  room = peer.joinRoom(gon.token, {
    mode: "mesh",
    stream: localStream,
  });

  room.on('open', () => {
    disp_video_alert("入室しました");
    send({
      person: gon.person,
      peer_id: peer.id
    })
    connect_persons[peer.id] = gon.person;
  });

  room.on('stream', stream => {

    var shere_id = $("#my-screenshere-id")
    if (shere_id.length > 0 && shere_id.text() == stream.peerId) {
      return;
    }

    var newVideo = $('<video playsinline=true autoplay controls>');
    newVideo.get(0).srcObject = stream;
    newVideo.attr("data-peer-id", stream.peerId);

    $("#videos").append(newVideo);
    newVideo[0].play();

    $(document).trigger('stream_video', [newVideo]);
    disp_video_alert(stream.peerId + "と接続しました");
  });

  // for closing room members
  room.on('peerLeave', peerId => {
    const remoteVideo = $("#videos").find("video[data-peer-id=" + peerId + "]");

    if (remoteVideo.length > 0) {
      remoteVideo.get(0).srcObject.getTracks().forEach(track => track.stop());
      remoteVideo.get(0).srcObject = null;
      remoteVideo.get(0).remove();
    }

    if (peerId in connect_persons) {
      delete connect_persons[peerId];
    }

    $(document).trigger('examination_timer', check_person());

    $(document).trigger('leave_video');
    disp_video_alert(peerId + "さんが退出しました");
  });

  // for closing myself
  room.once('close', () => {
    Array.from($("#videos").children()).forEach(remoteVideo => {
      remoteVideo.srcObject.getTracks().forEach(track => track.stop());
      remoteVideo.srcObject = null;
      remoteVideo.remove();
    });

    disp_video_alert("退出しました");

    $(".make-call").show();
    $(".end-call").hide();
  });

  room.on("data", function (data) {
    console.log(data)
    var info = data.data;
    connect_persons[info.peer_id] = info.person;
    $(document).trigger('examination_timer', check_person());
  });

  room.on('error', function (err) {
    disp_video_alert(err.message);
    send_log("", err.message)
    $("#log").append($("<p>エラーが発生しました。"+ err.toString() + new Date().toLocaleString() + "</p>"));
  });

}

function end_call() {
  if (room == null) {
    return;
  }
  room.close(), {
    once: true
  }
  room = null;
}

function send(data) {
  room.send(data)
}

function check_person() {
  var doctor = 'doctor'
  var patient = 'patient';
  var doctor_exist = false;
  var patient_exist = false;
  Object.keys(connect_persons).forEach(function (key) {
    if (connect_persons[key] == doctor) {
      doctor_exist = true;
    } else if (connect_persons[key] == patient) {
      patient_exist = true;
    }
  });
  return doctor_exist && patient_exist;
}


const send_log = (code, log) => {
  $.ajax({
      url: "/examination_room/send_log",
      type: "POST",
      data: {
        code: code,
        log: log,
        user_agent: window.navigator.userAgent.toLowerCase(),
        site_url: window.location.href
      },
      dataType: "json"
    })
    // .done(function (data) {

    // })
    .fail(function (data) {
      alert('エラーが発生しました。')
      $("#log").append($("<p>エラーが発生しました。"+ data.toString() + new Date().toLocaleString() + "</p>"));
    })
}