// newはしないでCreateVideo.init(imageDataUries, ms)を呼び出してください

export {
  CreateVideo
}

class CreateVideo {
  constructor(imageDataUries, ms) {

    this.imageDataUries = imageDataUries;
    this.images = []

    this.ms = ms;
    this.div = $("<div>");
    this.videoDiv = $("<div class='videoDiv'>");
    this.buttonsDiv = $("<div class='buttons'>");

    //canvasの設定
    this.videoCanvas = $("<canvas>").addClass("video");
    this.count = 0;
    this.videoDiv.append(this.videoCanvas);
    this.div.append(this.videoDiv);

    //ボタン類の設定
    var startButton = $("<button>").addClass("videoStartButton btn-lg btn-success").append("<img src='/application/icon/play-fill.svg'>")
    var stopButton = $("<button>").addClass("videoStopButton btn-lg btn-danger").append("<img src='/application/icon/stop-fill.svg'>")
    startButton.on("click", this.start.bind(this));
    stopButton.on("click", this.stop.bind(this));
    this.buttonsDiv.append(startButton);
    this.buttonsDiv.append(stopButton);
    this.div.append(this.buttonsDiv);

  }

  static async init(imageDataUries, ms) {
    const createVideo = new CreateVideo(imageDataUries, ms);

    try {
      $("#log").append($("<p>" + "ビデオ作成" + "</p>"));

      // 全てのイメージのonloadを待つ
      await createVideo.loadImages();

      // 描画キャンバスを最初のイメージに合わせる
      createVideo.adjustCanvas();
      // 最初の１枚を描画
      createVideo.draw();
      createVideo.reset();
      $("#log").append($("<p>" + "ビデオ作成完了" + "</p>"));

    } catch (error) {
      console.error(error);
      $("#log").append($("<p>" + error.toString() + "</p>"));

    }
    return createVideo;
  }

  showVideoInModal(modalSelector) {
    this.modal = modalSelector;
    if (!this.modal) {
      console.log("ビデオ生成処理に失敗しました。モーダルが指定されていません")
    }
    var modal = $(this.modal)
    modal.find(".modal-body").empty();
    modal.find(".modal-body").append(this.getVideoDiv());

    modal.modal(); //show

    // モーダルが開いてからキャンバスサイズを合わせ、最初の１フレームを描画
    $(modal).on("shown.bs.modal", () => {
      this.adjustCanvas();
      // 最初の１枚を描画
      this.draw();
      this.reset();
    })

  }

  getVideoDiv() {
    try {
      return this.div;
    } catch (error) {
      console.error(error);
      $("#log").append($("<p>" + error.toString() + "</p>"));

    }
  }

  start() {
    try {
      this.stop();
      if (this.count >= this.imageDataUries.length) {
        this.count = 0;
      }
      this.adjustCanvas();
      this.intervalId = setInterval(this.draw.bind(this), this.ms);
    } catch (error) {
      console.error(error);
      $("#log").append($("<p>" + error.toString() + "</p>"));
    }
  }

  draw() {
    try {
      var drawImg = this.images[this.count];
      var x = this.videoCanvas.width();
      var y = this.videoCanvas.height();
      this.videoCanvas[0].getContext('2d').drawImage(drawImg, 0, 0, drawImg.width, drawImg.height, 0, 0, x, y);
      this.count++;

      if (this.count >= this.imageDataUries.length) {
        this.reset();
      }
    } catch (error) {
      console.error(error);
      $("#log").append($("<p>" + error.toString() + "</p>"));
    }
  }

  stop() {
    try {
      clearInterval(this.intervalId);
      this.intervalId = "";
    } catch (error) {
      console.error(error);
      $("#log").append($("<p>" + error.toString() + "</p>"));
    }
  }
  reset() {
    try {
      clearInterval(this.intervalId);
      this.intervalId = "";
      this.count = 0;
    } catch (error) {
      console.error(error);
      $("#log").append($("<p>" + error.toString() + "</p>"));
    }
  }

  adjustCanvas() {
    var img = this.images[0];

    if (img.width < this.videoCanvas.parent().width()) {
      this.videoCanvas.attr("width", img.width);
      this.videoCanvas.attr("height", img.height);
    } else {
      this.videoCanvas.attr("width", this.videoCanvas.parent().width());
      this.videoCanvas.attr("height", img.height * (this.videoCanvas.width() / img.width));
    }
  }

  loadImages() {
    var p = []
    this.imageDataUries.forEach(function (uri) {
      p.push(this.loadImage(uri));
    }.bind(this))

    // 全画像待ち
    return Promise.all(p);
  }

  loadImage(uri) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.src = uri
      img.onload = function (selfInstance) {
        selfInstance.images.push(this);
        resolve(img);
      }.bind(img, this);
      img.onerror = (e) => reject(e);

    });
  }

}