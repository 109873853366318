import moment from 'moment'
export {
  Timer
}

class Timer {

  constructor(datetime, func) {
    this.datetime = datetime;
    this.func = func;
  }

  culc_diff(datetime) {

    //現在の時刻
    var _now = moment();
  
    //目標時刻
    var _set = moment(datetime);
  
    console.log(_now);
    console.log(_set);
  
    var diff = _set.diff(_now);
    console.log(diff);

    return diff;
  
  }

  start() {
    if(this.timer == null){
      this.culc_diff(this.datetime)
      this.timer = setTimeout(this.func, this.culc_diff(this.datetime))
    }
  }

  stop(){
    if (!this.timer) {return;}
    clearInterval(this.timer);
    this.timer = null;
  }
}

