import consumer from "./consumer"
export {
  chatChannel,
  is_connect
}

var is_connect = false;
var chatChannel = null;

$(function () {

  if ($(".chat").length < 1){ return; }

  chatChannel = consumer.subscriptions.create({
    channel: "ChatChannel",
    channelid: gon.channel_id
  }, {
    initialized() {
      console.log("サブスクリプションが作成されました。" + new Date())
    },
    connected() {
      console.log("接続しました。" + new Date())
      is_connect = true;
    },
    disconnected() {
      console.log("切断されました。" + new Date())
      is_connect = false;
    },
    rejected() {
      console.log("サブスクリプションがサーバーで却下されました。" + new Date())
    },
    speak(body, image, tags) {
      console.log("メッセージを送信します。" + new Date())
      return this.perform('speak', {
        body: body,
        image: image,
        tags: tags
      });
    },
    reply(body, image, tags, parent_message_id) {
      console.log("メッセージ返信を送信します。" + new Date())
      return this.perform('reply', {
        body: body,
        image: image,
        tags: tags,
        parent_message_id: parent_message_id
      });
    },
    message_update(body, tags, message_id) {
      console.log("メッセージ更新を送信します。" + new Date())
      return this.perform('message_update', {
        body: body,
        tags: tags,
        message_id: message_id
      });
    },
    message_delete(message_id) {
      console.log("メッセージ削除を更新します。" + new Date())
      return this.perform('message_delete', {
        message_id: message_id
      });
    },
    received(data) {
  
      var mode = data.mode
  
      if (mode == "speak") {
        console.log("メッセージを受信しました。" + new Date());
        $(document).trigger('received_speak', [data]);

      }else if(mode == "caring"){
        console.log("見守りメッセージを受信しました。" + new Date());
        $(document).trigger('received_speak', [data]);

      } else if (mode == "update") {
        console.log("メッセージ更新を受信しました。" + new Date());
        $(document).trigger('received_update', [data]);
  
      } else if (mode == "delete") {
        console.log("メッセージ削除を受信しました。" + new Date());
        $(document).trigger('received_delete', [data]);
  
      } else if (mode == "reply") {
        console.log("返信メッセージを受信しました。" + new Date());
        $(document).trigger('received_reply', [data]);

      } else if (mode == "error") {
        console.log("エラーが発生しました" + new Date());
        $(document).trigger('received_error', [data]);

      } else {
        console.log("不明なモードを受信しました" + new Date());
      }
  
    }
  });


});