export {
  Recording
}
import {
  disp_video_alert
} from "../ui";

class Recording {

  constructor() {
    this.init();
  }

  init() {

    // 統合用のAudioContext
    const ctx = new AudioContext();
    const dest = ctx.createMediaStreamDestination();

    // 自分の音声を登録
    if (Recording.hasAudioTrack($("#my-video")[0].srcObject)) {
      ctx.createMediaStreamSource($("#my-video")[0].srcObject).connect(dest);
    }

    // 通話先の音声を登録
    $("#videos video").each(function (index, video) {
      if (Recording.hasAudioTrack($(video)[0].srcObject)) {
        ctx.createMediaStreamSource($(video)[0].srcObject).connect(dest);
      }
    })

    // レコーダー作成
    this.recorder = new MediaRecorder(dest.stream);
    this.recorder.ondataavailable = Recording.handleDataAvailable;

  }

  start() {
    this.recorder.start();
    disp_video_alert("録音を開始しました");
  }

  stop() {
    this.recorder.stop();
    disp_video_alert("録音を停止しました");
  }

  static handleDataAvailable(event) {
    var recordedChunks = [];

    if (event.data.size > 0) {

      recordedChunks.push(event.data);
      var superBuffer = new Blob(recordedChunks);
      var url = window.URL.createObjectURL(superBuffer);

      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = 'rec.webm';
      a.click();

    } else {
      disp_video_alert("error: 録音に失敗しました。");
    }
  }

  static hasAudioTrack(obj) {

    // videoの内容がMediaStreamかつ、音声情報が存在するかチェック
    if (obj instanceof MediaStream &&
      obj.getAudioTracks().length > 0) {
      return true;
    } else {
      return false;
    }
  }
}