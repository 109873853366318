import {
  disp_video_alert
} from "../ui";
export {
  ScreenShotUpload
}

class ScreenShotUpload {

  constructor(videoSelector, canvasSelector, ms, receiverChannel) {
    this.video = videoSelector;
    this.canvas = $(canvasSelector);
    this.ms = ms; // msミリ秒ごとにスクリーンショットを取る
    this.uploadMs = 10000; //uploadMsミリ秒毎に撮り溜めたスクリーンショットをアップロードする
    this.receiverChannel = receiverChannel // ActionCable送信先
    this.funcCompleteLog(this.constructor.name)
  }

  startScreenShotEverySecond() {
    try {
      if (this.intervalId) {
        return;
      }
      var video = $(this.video);
      if (video.length < 1) {
        return;
      }
      this.datauri = [];
      this.intervalId = setInterval(this.capture.bind(this), this.ms);
      this.uploadIntervalId = setInterval(this.imageUpload.bind(this), this.uploadMs);
      disp_video_alert("見守りを開始しました");
    } catch (error) {
      console.error(error);
      this.log(error.toString())
    }
    this.funcCompleteLog(this.startScreenShotEverySecond.name)
  }

  stopScreenShotEverySecond() {
    try {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      if (this.uploadIntervalId) {
        clearInterval(this.uploadIntervalId);
      }

      this.intervalId = "";
      this.uploadIntervalId = "";
      this.datauri = [];
      disp_video_alert("見守りを停止しました");

    } catch (error) {
      console.error(error);
      this.log(error.toString())
    }
    this.funcCompleteLog(this.stopScreenShotEverySecond.name)
  }

  captureVideo() {
    try {
      var video = $(this.video);
      if (video.length < 1) {
        return false;
      }

      this.canvas.attr('width', video[0].videoWidth);
      this.canvas.attr('height', video[0].videoHeight);
      this.canvas[0].getContext('2d').drawImage(video[0], 0, 0, video[0].videoWidth, video[0].videoHeight);
      this.datauri.push(this.canvas[0].toDataURL());

      return true;
    } catch (error) {
      console.error(error);
      this.log(error.toString())
    }
    this.funcCompleteLog(this.captureVideo.name)
  }

  capture() {
    try {
      this.captureVideo();

    } catch (error) {
      console.error(error);
      this.log(error.toString())
    }
    this.funcCompleteLog(this.capture.name)
  }

  imageUpload() {

    try {
      if (this.datauri.length < 1) {
        return;
      }
      var updateDatauri = this.datauri;
      this.datauri = [];
      this.receiverChannel.image(updateDatauri);
    } catch (error) {
      console.error(error);
      this.log(error.toString())
    }
    this.funcCompleteLog(this.imageUpload.name)
  }

  funcCompleteLog(funcName) {
    var body = funcName + "完了"
    this.log(body);
  }

  log(body) {
    body = body + new Date().toLocaleString();
    $("#log").append($("<p>" + body + "</p>"));
    console.log(body)
  }
}