import moment from 'moment'
import {
  disp_video_alert
} from "./ui";
import {
  is_display
} from "../common/ui";
import {
  Timer
} from "../common/datetime";
import {
  call,
  end_call
} from "./room";
import {
  videoManagementChannel
} from "../channels/videochat_management_channel"
import {
  ScreenShot
} from "./examination_support/screenshot"
import {
  SelectElement
} from "./examination_support/selectelement"
import {
  Scaling
} from "./examination_support/scaling"
import {
  ScreenShere
} from "./examination_support/screenshere"
import {
  Recording
} from "./examination_support/recording"
import {
  ScreenShotUpload
} from "./examination_support/screenShotUpload"

$(function () {

  if (!is_display(".video_chat.examination")) {
    return;
  }

  if (!'gon' in window || 'gon' in window && !'token' in gon) {
    disp_video_alert("この見守りカメラは有効期限切れです。")
    return;
  }
  if ('token' in gon && (!gon.token || gon.token == "")) {
    disp_video_alert("トークンの取得に失敗しました。")
    return;
  }

  $(".make-call").show();
  $(".end-examination").show();

  $('.make-call').on("click", function () {
    $('.reload:visible').popover('show');
    call();
    $(".make-call").hide();
    $("#support").show();
  });

  $(".end-examination").on("click", function(){
    videoManagementChannel.invalid(); // 有効期限切れ通知
  });
  
  $(document).on('video_chat_invalid', function (e, data) {
    location.href = "/examination_room/"+ gon.channel_id +"/examination_end";
  });

  $(document).on('video_chat_info', function (e, data) {
    expire(data['message']['message']);
  });

  var select = new SelectElement();
  $(document).on('stream_video', function(e, video){
    select.add(video);
  });

  $(document).on('leave_video', function(){
  });

  
  $('.screenshot').on('click', function(){
    var screenshot = new ScreenShot($('video.active'), $('#modalTemp'), $("#capture"));
    screenshot.capture_to_modal();
    var scaling = new Scaling($("#capture"));
    $('.zoom_up').on('click', function(){
      scaling.zoom_up();
    });
    $('.zoom_out').on('click', function(){
      scaling.zoom_out();
    });
    $('.grid').on('click', function(){
      scaling.switch_grid();
    });
    $('.close').on('click', function(){
      scaling.destory();
      $('.zoom_up').off();
      $('.zoom_out').off();
      $('.grid').off();
      $('.close').off();
    });
    
  });

  
  $('.recording').on('click', function(){
    var recorder = new Recording();
    recorder.start();
    $('.recording').addClass("hidden"); // hide
    $('.end_recording').removeClass("hidden"); // show

    $('.end_recording').on('click', function(){
      recorder.stop();
      $('.recording').removeClass("hidden"); // show
      $('.end_recording').addClass("hidden"); // hide
      $('.end_recording').off();
      
    })
  });
  

  $('.screenshere').on('click', async function () {

    var screenshere = new ScreenShere();
    $('.end_screenshere').removeClass("hidden"); // show
    $('.screenshere').addClass("hidden"); // hide

    $('.end_screenshere').on('click', function (){

      screenshere.end_shere();
      $('.end_screenshere').addClass("hidden"); // hide
      $('.screenshere').removeClass("hidden"); // show
      $('.end_screenshere').off();

    })
  })

  $('.screenShotUpload').on('click', function(){

    var screenShotUpload = new ScreenShotUpload($('#my-video'), $("#capture"), 500, videoManagementChannel);
    screenShotUpload.startScreenShotEverySecond(screenShotUpload);

    $('.endScreenShotUpload').removeClass("hidden"); // show
    $('.screenShotUpload').addClass("hidden"); // hide

    $('.endScreenShotUpload').on('click', function(){
      screenShotUpload.stopScreenShotEverySecond();
      $('.endScreenShotUpload').addClass("hidden"); // hide
      $('.screenShotUpload').removeClass("hidden"); // show
      $('.endScreenShotUpload').off();
    });
    
  });


  var timer = new Timer(gon.expiration_time, function(){expire("有効期限切れです。再度見守りカメラを作成してください。")});
  timer.start();

  var timer1 = new Timer(moment().add(1, 'hour'), function(){expire("有効期限切れです。再度見守りカメラを作成してください。")});
  var timer2 = new Timer(
    moment().add(50, 'minute'),
    function(){disp_video_alert("あと10分で通話の有効期限が切れます。")}
  );

  $(document).on('examination_timer', function (e, data) {

    if(data){
      timer.stop();
      timer1.start();
      timer2.start();

    } else {
      timer.start();
      timer1.stop();
      timer2.stop();
    }
    
  });

});

function expire (message) {
  $(".make-call").hide();
  $(".end-examination").hide();
  end_call();
  location.href = "/examination_room/invalid?message=" + message;
}