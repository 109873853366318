export {
  firebaseInit,
  firebaseStatusCheck,
  firebaseCreateUserByMail,
  firebaseLoginByGoogle,
  firebaseLoginByMail,
  GoogleLoginAndAppLogin,
  MailLoginAndAppLogin,
  sendMailVerification,
  checkEmail,
  changePassword,
  checkRecaptcha,
  checkPhoneNumber,
  mergeUserProvider,
  csrfTokenObj,
  authorizationObj,
  railsLogin,
  firebaseSignOut,
  firebaseError,
  currentUser,
  sendPasswordResetEmail,
  getAuth,
  authStateChanged,
  getParameterByName,
}
import {
  disp_alert
} from "../common/ui"

import {
  getFirebaseConfig
} from "./firebase_config"

import firebase from "firebase/app";

require('firebaseui');

const firebaseConfig = getFirebaseConfig();
$(function () {
  firebaseInit();
});

const firebaseInit = (config) => {

  if (!config) {
    config = firebaseConfig
  }
  // Initialize Firebase
  firebase.initializeApp(config);
  firebase.auth().languageCode = 'ja';
}

const getAuth = async () => {
  await authStateChanged();
  return firebase.auth();
}
const currentUser = async () => {
  await authStateChanged();
  return firebase.auth().currentUser;
}

const authStateChanged = () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged(() => {
      resolve();
    });
  });
}

const firebaseStatusCheck = async () => {
  var status = {
    NOT_LOGIN: {
      value: 'NOT_LOGIN',
      message: 'ログインしてください'
    },
    CONTRACTOR_NOT_AUTH: {
      value: 'CONTRACTOR_NOT_AUTH',
      message: 'メールアドレス・電話番号を認証してください（契約者）'
    },
    CONTRACTOR_SHOULD_AUTH_PHONE_NUMBER: {
      value: 'CONTRACTOR_SHOULD_AUTH_PHONE_NUMBER',
      message: '電話番号を認証してください（契約者）'
    },
    CONTRACTOR_SHOULD_AUTH_EMAIL: {
      value: 'CONTRACTOR_SHOULD_AUTH_EMAIL',
      message: 'メールアドレスを認証してください（契約者）'
    },
    ALL_AUTHENTICATED: {
      value: 'ALL_AUTHENTICATED',
      message: 'すべて認証済みです'
    }
  };


  var user = await currentUser();
  var isExistPhone = false;

  if (!user) {
    return status.NOT_LOGIN;
  }

  user.providerData.forEach(function (profile) {
    console.log("Sign-in provider: " + profile.providerId);
    if (profile.providerId == "phone") {
      isExistPhone = true;
    }
  });

  // 契約者の場合は電話番号とメールアドレス認証済みかチェック
  if (gon.user_info.role == "contractor_admin"){
    if (isExistPhone && user.emailVerified) {
      return status.ALL_AUTHENTICATED;
    } else if (isExistPhone) {
      return status.CONTRACTOR_SHOULD_AUTH_EMAIL;
    } else if (user.emailVerified) {
      return status.CONTRACTOR_SHOULD_AUTH_PHONE_NUMBER;
    } else {
      return status.CONTRACTOR_NOT_AUTH;
    }
  }

}

const GoogleLoginAndAppLogin = async () => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  var userCredential = await firebaseLoginByGoogle();
  var idToken = await userCredential.user.getIdToken(true);
  railsLogin(idToken);
}

const MailLoginAndAppLogin = async () => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  var userCredential = await firebaseLoginByMail();
  var idToken = await userCredential.user.getIdToken(true);
  railsLogin(idToken);
}

const firebaseLoginByGoogle = async () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  return firebase.auth().signInWithPopup(provider)
}

const firebaseLoginByMail = async (email, password) => {

  if (!password) {
    password = $(".password").val();
  }

  if (!email) {
    email = $(".email").val();
  }

  if (!password || !email) {
    throw {
      "code": "app-00",
      "message": "メールアドレスまたはパスワードが入力されていません。"
    };
  }

  return firebase.auth().signInWithEmailAndPassword(email, password);
}
const passwordCheck = (password, password_confirm) => {

  if (!password || password != password_confirm) {
    throw {
      "code": "app-01",
      "message": "パスワードとパスワード（確認）が一致しません。"
    };
  }
}

const firebaseCreateUserByMail = async (password, password_confirm, email) => {

  passwordCheck(password, password_confirm);

  return firebase.auth().createUserWithEmailAndPassword(email, password).then(function () {
    $("#mail-input-area").hide();
    $("#signin-methods").hide();
  }).catch((error) => {
    if (error.code == "auth/email-already-in-use") {
      firebaseLoginByMail(email, password)
    } else {
      throw error
    }
  });
}

const sendMailVerification = async (user) => {

  if (!user) {
    user = firebase.auth().currentUser;
  }

  return user.sendEmailVerification()
}

const checkRecaptcha = async (phoneNumber) => {
  var appVerifier = new firebase.auth.RecaptchaVerifier(
    'recaptcha-container', {
      'size': 'normal',
      'callback': function (response) {
        onSolvedRecaptcha();
      },
      'expired-callback': function () {}
    });
  var confirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)

  return confirmationResult
}

const checkPhoneNumber = (verificationCode, confirmationResult) => {

  if (!verificationCode) {
    throw {
      "code": "app-07",
      "message": "確認コードを入力してください。"
    };
  }
  return firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);

}

const mergeUserProvider = async (prevUser, credential) => {
  return prevUser.linkWithCredential(credential)
}

const checkEmail = async () => {

  var mode = getParameterByName('mode');
  var actionCode = getParameterByName('oobCode');
  var continueUrl = getParameterByName('continueUrl');

  switch (mode) {
    case 'resetPassword':
      return await handleResetPassword(actionCode, continueUrl);
    case 'recoverEmail':
      return await handleRecoverEmail(actionCode);
    case 'verifyEmail':
      return await handleVerifyEmail(actionCode);
    default:
      return false;
  }
}

const sendPasswordResetEmail = (email) => {
  return firebase.auth().sendPasswordResetEmail(email)
}

const handleResetPassword = async (actionCode) => {
  //https://firebase.google.com/docs/auth/custom-email-handler
  await firebase.auth().verifyPasswordResetCode(actionCode)

  $(".form-signin").show();
  $(".password_form").show();
  $(".login_link").show();
  $(".submit_password").on('click', async function () {
    try {
      var password = $(".password_form .password").val();
      var password_confirm = $(".password_form .password_confirm").val();

      passwordCheck(password, password_confirm);
      await firebase.auth().confirmPasswordReset(actionCode, password);
      $("#message").text("パスワード変更が完了しました。");
      $(".password_form").hide();
    } catch (error) {
      firebaseError(error);
    }
  })
}

const handleRecoverEmail = async (actionCode) => {
  //https://firebase.google.com/docs/auth/custom-email-handler
  var info = await firebase.auth().checkActionCode(actionCode);
  var restoredEmail = info['data']['email'];
  console.log("restore: " + "restoredEmail");
  await firebase.auth().applyActionCode(actionCode);
  $("#message").text("メールアドレスの復元が完了しました。" + "メールアドレス：" + restoredEmail);
}

const handleVerifyEmail = async (actionCode) => {
    await firebase.auth().applyActionCode(actionCode);
    $("#message").text("メールアドレスの確認が完了しました。");
    console.log("メールチェック完了");
    return true;
}

const csrfTokenObj = () => {
  return {
    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')
  };
}

const authorizationObj = (idToken) => {
  return {
    "Authorization": `Bearer ${idToken}`
  };
}

const changePassword = async (password, password_confirm) => {
  passwordCheck(password, password_confirm);
  var user = firebase.auth().currentUser;
  return user.updatePassword(password);
}

const firebaseSignOut = () => {
  firebase.auth().signOut().then(() => {
      console.log("firebaseログアウトしました");
      railsLogout();
    })
    .catch((error) => {
      console.log(`ログアウト時にエラーが発生しました (${error})`);
    });
}

const railsLogin = async (idToken) => {
  if (!idToken) {
    var user = firebase.auth().currentUser;
    idToken = await user.getIdToken(true);
  }
  // const url = isNewUser ? "/users/sign_up/social_sign_in" : "/users/sign_in";
  const url = gon.user_info.url;
  const headers = Object.assign(csrfTokenObj(), authorizationObj(idToken));
  $.ajax({
      url: url,
      type: "POST",
      headers: headers
    })
    .done((data) => {
      console.log("APP login!")
      $(document).trigger("appLogin");
      if ("is_redirect" in gon.user_info && !gon.user_info.is_redirect) {
        return;
      }
      if ('result' in data && 'url' in data.result) {
        window.location.href = data.result.url;
      } else {
        window.location.href = '/channel/index';
      }

    })
    .fail((data) => {
      console.log("APP login failed!");
      var message = "ログインに失敗しました";
      var detail_code = "";
      if ('responseJSON' in data && "result" in data.responseJSON && "message" in data.responseJSON.result) {
        message = data.responseJSON.result.message;
        detail_code = data.responseJSON.result.code;
      }
      if ('responseJSON' in data && "token" in data.responseJSON) {
        $('meta[name="csrf-token"]').attr('content', data.responseJSON.token);
      }
      if (data.status == "422") {
        var fail_message = "ログインに失敗しました。お手数ですがもう一度ログインしてください"
        window.location.href = '/users/sign_in/?message=' + fail_message;
      }
      firebaseError({
        message: message,
        code: data.status
      });
    });
}

const railsLogout = () => {
  const url = "/users/sing_out"
  $.ajax({
      url: url,
      type: "POST",
      data: {
        "_method": "DELETE"
      }
    })
    .done((data) => {
      console.log("APP logout!")
      window.location.href = '/users/sign_in';
    })
    .fail((data) => {
      console.log("APP logout failed!");
      var message = "ログアウトに失敗しました"
      if ('responseJSON' in data && "result" in data.responseJSON && "message" in data.responseJSON.result) {
        message = data.responseJSON.result.message;
      }
      firebaseError({
        message: message,
        code: data.status
      });
    });
}

const firebaseError = (error) => {
  var error_message = "エラーが発生しました。";

  if (error.code == "auth/email-already-in-use") {
    error_message = "ユーザーを二重で登録することはできません。"

  } else if (error.code == "auth/popup-closed-by-user") {
    error_message = "ユーザーによりポップアップが閉じられました。"
    console.log("エラーが発生しました。コード：" + error.code + "　／　メッセージ：" + error_message);
    return;

  } else if (error.code == "auth/user-not-found") {
    error_message = "ユーザーが存在しません。入力内容をご確認ください。"

  } else if (error.code == "auth/wrong-password") {
    error_message = "パスワードが違います。"

  } else if (error.code == "auth/invalid-action-code") {
    error_message = "有効期限切れのURLです。お手数ですが再度認証を行ってください。"

  } else if (error.code == "auth/weak-password") {
    error_message = "パスワードは6文字以上入力してください。"

  } else if (error.code == "auth/too-many-requests") {
    error_message = "時間をあけて再度やりなおしてください。"

  } else if (error.code == "auth/invalid-verification-code") {
    error_message = "確認コードが違います。"

  } else if (error.code == "auth/code-expired") {
    error_message = "確認コードが有効期限切れです。もう一度やり直してください。"

  } else {
    error_message = "エラーが発生しました。メッセージ：" + error.message

  }

  disp_alert(error_message + "　コード：" + error.code);
  console.log("エラーが発生しました。コード：" + error.code + "　／　メッセージ：" + error.message);
}

const getParameterByName = (name) => {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}