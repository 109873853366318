import {
  chatChannel,
  is_connect
} from "./chat"
import {
  disp_spinner_button
} from "../common/ui"

export {
  submit_message,
  submit_reply_message
}


async function submit_message(body_selector, image_selector, tags_selector) {
  disp_spinner_button("#chatsubmit", true);
  if (!is_connect) {
    alert("接続中のためもう少し経ってからお試しください");
    disp_spinner_button("#chatsubmit", false);
    return false;
  }

  var body = $(body_selector).val();
  var tags = $(tags_selector).val();

  var image = await get_image_uri(image_selector).catch(e => {
    console.log('onload error', e);
  });

  if (!body && !image) {
    disp_spinner_button("#chatsubmit", false);
    return false;
  }

  chatChannel.speak(body, image, tags);
  clear_input_value(body_selector, image_selector, tags_selector);
}

async function submit_reply_message(body_selector, image_selector, tags_selector, parent_message_id) {
  if (!is_connect) {
    alert("接続中のためもう少し経ってからお試しください");
    return false;
  }

  var body = $(body_selector).val();
  var tags = $(tags_selector).val();
  var image = await get_image_uri(image_selector).catch(e => {
    console.log('onload error', e);
  });

  if (!parent_message_id || (!body && !image)) {
    return false;
  }

  chatChannel.reply(body, image, tags, parent_message_id);
  clear_input_value(body_selector, image_selector, tags_selector)

}

function get_image_uri(image_selector) {
  return new Promise((resolve, reject) => {

    if (!image_selector) {
      resolve(null)
      return;
    }

    var image_dom = $(image_selector)

    if (image_dom && image_dom.get(0).files.length > 0) {

      var file_reader = new FileReader();
      var image_blob = image_dom.get(0).files[0];
      file_reader.readAsDataURL(image_blob);

      file_reader.onload = function (e) {
        resolve(file_reader.result) // image uri
      }
      file_reader.onerror = function (e) {
        reject(e)
      }
    } else {
      resolve(null)
      return;
    }
  })
}

function clear_input_value(body_selector, image_selector, tags_selector) {
  $(body_selector).val("");
  $(image_selector).val("").change();
  $(tags_selector).val("").change();
}