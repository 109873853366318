export {
  ScreenShere
}
import {
  disp_video_alert
} from "../ui";

class ScreenShere {

  constructor() {
    this.room = null;
    this.peer = null;
    this.localStream = null;

    this.init()
  }

  async init() {

    this.peer = new Peer({
      key: gon.skyway_api_key,
      debug: 3
    });

    this.peer.on('error', function (err) {
      disp_video_alert('error: 画面共有に失敗しました。 ' + err.message);
    });

    await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: false
      })
      .then(function (stream) {
        // Success
        this.localStream = stream;
        this.shere();
      }.bind(this)).catch(function (error) {
        // Error
        disp_video_alert('error: 画面共有に失敗しました。mediaDevice.getDisplayMedia() ', error);
        return;
      });
  }


  shere() {

    if (this.peer == null || this.localStream == null) {
      disp_video_alert("error: 画面共有に失敗しました。peerまたはstreamが存在しません。");
      return;
    }

    $('#my-screenshere-video').show();
    $('#my-screenshere-video').get(0).srcObject = this.localStream;
    $('#my-screenshere-video')[0].play();

    this.room = this.peer.joinRoom(gon.token, {
      mode: "mesh",
      stream: this.localStream,
    });

    this.room.on("data", function (data) {
      console.log(data);
    });

    this.room.on('error', function (err) {
      disp_video_alert(err.message);
    });

    $('#my-screenshere-id').text(this.peer.id);

    $("#my-screenshere-video").on("suspend", function () {
      this.end_shere();
    }.bind(this));

  }

  end_shere() {
    if (this.room == null) {
      return;
    }
    this.room.close(), {
      once: true
    }
    this.room = null;
    $('#my-screenshere-video').hide();

    if (this.localStream) {
      this.localStream.getTracks().forEach(track => track.stop());
    }

  }

}