import consumer from "./consumer"
export {
  videoManagementChannel,
  is_connect
}

var is_connect = false;
var videoManagementChannel = null;

$(function () {
  $("#log").append($("<p>ビデオチャット判定" + new Date().toLocaleString() + "</p>"));
  if ($(".video_chat").length < 1 &&
   $(".chat").length < 1){ return; }
   $("#log").append($("<p>ビデオチャット判定OK" + new Date().toLocaleString() + "</p>"));

  videoManagementChannel = consumer.subscriptions.create({
    channel: "VideochatManagementChannel",
    channelid: gon.channel_id
  }, {
    initialized() {
      console.log("サブスクリプションが作成されました。" + new Date())
    },
    connected() {
      console.log("接続しました。" + new Date())
      $("#log").append($("<p>接続しました。" + new Date().toLocaleString() + "</p>"));
      is_connect = true;
    },
    disconnected() {
      console.log("切断されました。" + new Date())
      $("#log").append($("<p>切断されました。" + new Date().toLocaleString() + "</p>"));
      is_connect = false;
    },
    rejected() {
      console.log("サブスクリプションがサーバーで却下されました。" + new Date())
      $("#log").append($("<p>サブスクリプションがサーバーで却下されました。" + new Date().toLocaleString() + "</p>"));
    },
    invalid() {
      console.log("見守りカメラ終了" + new Date())
      $("#log").append($("<p>見守りカメラ終了" + new Date().toLocaleString() + "</p>"));
      return this.perform('invalid', {
      });
    },
    expire() {
      console.log("見守りカメラ有効期限切れを通知します。" + new Date())
      $("#log").append($("<p>見守りカメラ有効期限切れを通知します。" + new Date().toLocaleString() + "</p>"));
      return this.perform('expire', {
      });
    },
    info() {
      console.log("見守りカメラのステータスを通知します。" + new Date())
      $("#log").append($("<p>見守りカメラのステータスを通知します。" + new Date().toLocaleString() + "</p>"));
      return this.perform('invalid', {
      });
    },
    image(image) {
      console.log("見守りカメラのカメラ映像を通知します。" + new Date())
      $("#log").append($("<p>見守りカメラのカメラ映像を通知します。" + new Date().toLocaleString() + "</p>"));
      return this.perform('image', {
        image: image,
      });
    },
    received(data) {
  
      var mode = data.mode
  
      if (mode == "expire") {
        console.log("ビデオチャット-有効期限切れ" + new Date());
        $(document).trigger('video_chat_expire', [data]);
      }else if(mode == "invalid"){
        console.log("ビデオチャット-通話終了" + new Date());
        $(document).trigger('video_chat_invalid', [data]);
      }else if(mode == "info"){
        console.log("ビデオチャット-インフォメーション" + new Date());
        $(document).trigger('video_chat_info', [data]);
      } else {
        console.log("不明なモードを受信しました" + new Date());
      }
  
    }
  });


});