import Rails from '@rails/ujs';
import {
  disp_spinner_button,
  disp_message,
  disp_messages,
  disp_date_hr,
  disp_date_hr_for_area
} from "../common/ui"
import {
  setting_edit_event
} from "./message_edit"
import {
  setting_reply_event
} from "./message_reply"
import {
  init_disp_messages,
  init_pager
} from "./message_common"
export {
  received_speak,
  received_update,
  received_delete,
  received_before_histroys,
  received_after_histroys,
  received_init_messages,
  received_error,
}
var histroy_count = 0;

function received_speak(e, data) {

  var message = data.message

  var message_dom = disp_message(message, $("#message_area"));
  setting_edit_event();
  setting_reply_event(); // リプライボタンイベントを設定
  disp_date_hr(message_dom);
  if (message.user && message.user.email == $("#user").data("user")) {
    var has_next_message = $("#next_paginate_area").find(".has_histroy").val();
    if (has_next_message === "true"){
      var form = $(".init_form");
      Rails.fire($(form)[0], "submit");
    }
    disp_spinner_button("#chatsubmit", false);
  }

  $('html, body').animate({
    scrollTop: $(document).height()
  }, {
    duration: 150
  });

}

function received_update(e, data) {

  var message = data.message;

  var selector = '[data-message_id="' + message.id + '"]';
  var current_message_dom = $(selector).closest("div.message");
  var message_dom = disp_message(message);
  current_message_dom.replaceWith(message_dom);
  setting_edit_event();
  setting_reply_event(); // リプライボタンイベントを設定
}

function received_delete(e, data) {

  var message = data.message;

  var selector = '[data-message_id="' + message.id + '"]';
  var message_content = $(selector);
  var deleted = message_content.find(".deleted");
  deleted.removeClass("hidden");

  message_content.find(".message_body").hide();
  message_content.find(".message_image").hide();
  message_content.find(".updated").hide();
  message_content.find(".message_tags").hide();
}

function received_before_histroys(e, data) {
  received_histroys(data, "#prev_paginate_area", data.pager_info.has_prev_message, data.pager_info, false);
  $("#oldest_message").val(data.messages[0].id)
}

function received_after_histroys(e, data) {
  received_histroys(data, "#next_paginate_area", data.pager_info.has_next_message, data.pager_info, true);
  $("#newest_message").val(data.messages[data.messages.length - 1].id)
}

function received_histroys(data, pagenate_selector, has_histroy, pager_info, append) {

  if (!has_histroy) {
    $(pagenate_selector).find(".histroy_button").hide();
    $(pagenate_selector).find(".none_histroy_text").show();
    $(pagenate_selector).find(".has_histroy").val(false);
  } else {
    $(pagenate_selector).find(".has_histroy").val(true);
  }

  if (data.messages) {
    if (pager_info.count) {
      histroy_count += pager_info.count;
      $(pagenate_selector).find(".count").text(histroy_count);
      $(pagenate_selector).find(".histroy_count_text").show();
      $(pagenate_selector).find(".histroy_count_text").animate({ opacity: 1 }, { duration: 500, easing: 'linear' });
      $(pagenate_selector).find(".histroy_count_text").animate({ opacity: 0 }, { duration: 2000, easing: 'linear' });
    }

    var message_dom = disp_messages(data.messages);
    var message_area = $("#message_area");
    var histroy_hr = $("<div>").addClass("histroyhr").append("<hr>").append("<span>" + histroy_count + "件のメッセージ</span>");

    if(append){
      message_area.append(histroy_hr);
      message_area.append(message_dom);
    } else {
      message_area.prepend(histroy_hr);
      message_area.prepend(message_dom);
    }

    setting_edit_event();
    setting_reply_event();
    disp_date_hr_for_area(message_area);

  }
}

function received_error(e, data){
  if (data){
    alert("エラーが発生しました" + "　" + data.message);
  } else {
    alert("エラーが発生しました");
  }
  
  disp_spinner_button("#chatsubmit", false);
}

function received_init_messages(e, data){
  init_disp_messages(data.messages)
  init_pager(data.messages, data.pager_info)
}