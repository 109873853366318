// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");
require("jquery-ui");
// require("firebase/auth");
// require("firebase/firestore");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//

import 'channel/new';
import 'chat/chat';
import 'chat/message_submit';
import 'chat/message_receive';
import 'chat/message_edit';
import 'chat/message_common';
import 'chat/search';
import 'video_chat/room';
import 'video_chat/ui';
import 'video_chat/examination';
import 'video_chat/patient';
import 'video_chat/examination_support/screenshot';
import 'video_chat/examination_support/selectelement';
import 'video_chat/examination_support/createVideo';
import 'common/ui';
import 'common/datetime';
import 'information/search';
import 'user/firebase';
import 'user/login';
import 'user/registrations_new';
import 'user/registrations_edit';
import 'user/registrations_new_other_user';
import 'user/check_email'
import 'user/password_reminder';
import 'user/auth_tel_and_email';
import 'count_tag/index';
import 'bootstrap';
import 'select2';
import 'moment/moment';
import 'moment';
import 'popper.js';
import 'lightbox2';

import '../stylesheets/application';

global.$ = jQuery;

import {
  firebaseSignOut,
} from "../user/firebase"
$(function () {
  $("#signout").on('click', function () {
    firebaseSignOut();
  })
});
